#print-label-component * {
  color: black;
}

#print-label-component {
  width:  210mm;
  height: 297mm;
}

#print-label-component table td {
  padding: 0.5rem;
}

#print-label-component table td p {
  margin: 0;
}

#print-label-component hr {
  border: 0;
  border-top: 1px solid #000;
}

#print-label-component td {
  white-space: normal !important;
}

#table-order-items {
  border: 1px solid white;
}

#table-order-items th {
  border: 1px solid white;
  color: white;
  padding: 0.3rem;
}

#table-order-items td {
  border: 1px solid white;
  color: black;
}

#table-item {
  border: 1px solid black;
  width: 100%; 
  /* height: 75%; */
}

#table-item-production td {
  padding: 0.2rem !important;
}