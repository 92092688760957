@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

body {
  margin: 0 !important;
  background-color: #f3f8ff !important;
  font-family: 'Roboto', sans-serif;
}

.table-color {
  .ant-table {
    .ant-table-thead > tr > th {
      position: relative;
      background: #f4f7fb;
      // width: 100vw;
    }
  }
}

.table-section-product {
  .ant-table {
    .ant-table-thead > tr > th {
      position: relative;
      background: #144272;
      color: #e4f6ff;
      font-size: small;
      font-weight: 500;
    }

    .ant-table-tbody .ant-table-row {
      display: table-row;
      vertical-align: top;
    }
  }

  .ant-table-wrapper .ant-table-tbody .ant-table-row:nth-child(2n + 1) .ant-table-cell-fix-left {
    position: relative;
    background: #f1f5f9;
    // border-radius: 10px;
    // width: 100vw;
  }

  .ant-table-wrapper .ant-table-thead > tr > td {
    position: relative;
    background: #144272;
    // border-radius: 10px;
    // width: 100vw;
  }

  .form-item-product {
    .ant-form-item {
      margin-bottom: 14px !important;

      .ant-form-item-label > label {
        height: 40px;
        font-weight: 600 !important;
      }
    }
  }
}

.table-section-leads {
  .ant-table {
    .ant-table-thead > tr > th {
      position: relative;
      background: #144272;
      color: #f3f8ff;
      font-size: small;
      font-weight: 500;
      // width: 100vw;
    }

    .ant-table-tbody .ant-table-row {
      display: table-row;
      vertical-align: top;
    }
  }
}

.table-section-invoice {
  .ant-table {
    .ant-table-thead > tr > th {
      border-radius: 0 !important;
      position: relative;
      background: #010101;
      color: #fff;
      // width: 100vw;
    }
  }
}

.section-summary-form {
  .ant-table {
    .ant-table-thead > tr > th {
      position: relative;
      background: #f4f7fb;
      // width: 100vw;
    }

    .ant-table-tbody .ant-table-row {
      display: table-row;
      vertical-align: top;
    }
  }
}

.section-detail-order {
  tr {
    vertical-align: top;
  }
}

.section-label-order {
  tr {
    vertical-align: top;
    border: 1px solid #4b4b4b;

    td {
      border: 1px solid #4b4b4b;
    }
  }
}

.section-form-user {
  .ant-input {
    border-radius: 8px !important;
    height: 2.5rem !important;
  }

  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: 8px !important;
    height: 2.5rem !important;
  }
}

.section-checkbox-permission {
  .ant-checkbox-group {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
}

.section-checkbox-form-order {
  .ant-checkbox-group {
    display: grid;
    grid-template-columns: 1fr;
  }
}

.section-checkbox-resi {
  .ant-checkbox-group {
    display: inline-block;
  }

  .ant-checkbox-wrapper + .ant-checkbox-wrapper {
    margin-inline-start: 0px;
  }
}

.section-checkbox-row {
  .ant-checkbox-group {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  .ant-checkbox-wrapper + .ant-checkbox-wrapper {
    margin-inline-start: 0px;
  }
}

.search-user {
  .ant-input-search .ant-input-group .ant-input-affix-wrapper:not(:last-child) {
    border-radius: 8px 0 0 8px !important;
  }

  .ant-input-search
    > .ant-input-group
    > .ant-input-group-addon:last-child
    .ant-input-search-button {
    border: 1px solid #06b6d4 !important;
    background-color: #06b6d4 !important;
    border-radius: 0 8px 8px 0 !important;
    padding-bottom: 3px;
    color: #fff;
  }
}

.section-form-login {
  .ant-input {
    border-radius: 8px !important;
    height: 2rem;
  }

  .ant-input-affix-wrapper {
    border-radius: 8px !important;
  }

  @media only screen and (max-width: 768px) {
    .ant-form-item .ant-form-item-label > label {
      color: #f3f8ff !important;
    }
  }
}

.ant-pagination {
  @apply flex order-5;

  .ant-pagination-item-link {
    display: flex !important;
    align-items: center;
    justify-content: center;
  }

  .ant-pagination-total-text {
    @apply mr-auto;
  }

  .ant-pagination-options {
    @apply float-left;
  }

  .ant-pagination-item {
    border-radius: 30px !important;
  }

  .ant-pagination-item-active {
    background-color: #144272;
    border: none;

    a {
      color: #fff;
    }
    a:hover {
      color: #fff;
    }
  }

  // Prev Next
  .ant-pagination-disabled .ant-pagination-item-link {
    border-radius: 30px;
  }
}

.ant-menu-light:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #06b6d438;
}

.section-payment-form {
  .ant-upload-wrapper.ant-upload-picture-card-wrapper .ant-upload.ant-upload-select {
    width: 8rem;
    background-color: #e4f6ff;
    border: 1px dashed #0cc1e5;
  }
}

.section-form-recipient {
  .ant-form-item {
    margin-bottom: 5px;

    .ant-form-item-label > label {
      font-weight: 600 !important;
    }
  }

  .ant-form-vertical .ant-form-item-label {
    padding: 0;
  }
}

.section-form-order {
  .ant-form-item {
    margin-bottom: 5px;

    .ant-form-item-label > label {
      font-weight: 600 !important;
    }
  }
  .ant-form-vertical .ant-form-item-label {
    padding: 0;
  }
}

.ant-btn-primary {
  background-color: #06b6d4;
}

.section-table-orders {
  .ant-picker .ant-picker-separator {
    height: 23px;
    padding: 0 40px;
  }

  label {
    color: #4b4b4b;
    font-size: 16px;
    font-weight: 500;
  }
}

.ant-switch {
  background: rgba(0, 0, 0, 0.25);
  .ant-switch-checked {
    background: #1890ff !important;
  }
}

#outer-circle {
  top: 29px !important;
  left: -21px !important;
  height: 45px !important;
  width: 45px !important;
}

#inner-circle {
  top: 2rem !important;
  left: 70% !important;
  height: 25px !important;
  width: 25px !important;
}

.advertiser-table {
  tbody {
    tr {
      td {
        border: 1px solid #e8e8e8;
        padding: 8px 12px 8px 12px;
      }
    }
  }
}

.search-user {
  .ant-input-search .ant-input-group .ant-input-affix-wrapper:not(:last-child) {
    border-radius: 8px 0 0 8px !important;
  }

  .ant-input-search
    > .ant-input-group
    > .ant-input-group-addon:last-child
    .ant-input-search-button {
    border: 1px solid #01497c !important;
    background-color: #01497c !important;
    border-radius: 0 8px 8px 0 !important;
    padding-top: 3px;
    color: #fff;
  }
}

.table-section-master-user {
  :where(.css-dev-only-do-not-override-1ck3jst).ant-table-wrapper .ant-table-thead > tr > td {
    position: relative;
    background: #111e2b;
    // border-radius: 10px;
    // width: 100vw;
  }

  .ant-table-thead > tr > th {
    position: relative;
    background: #144272;
    color: #b8bcc1;
    font-size: 11px;
    font-weight: 600;
    text-transform: uppercase;
    // width: 100vw;
  }

  .ant-table-tbody .ant-table-row {
    display: table-row;
  }

  .ant-table-wrapper .ant-table-tbody .ant-table-row:nth-child(2n + 1) .ant-table-cell-fix-left {
    position: relative;
    background: #f3f4f6;
    // border-radius: 10px;
    // width: 100vw;
  }
}

.dealmaker-report-table {
  thead {
    tr {
      th {
        border: 1px solid #e8e8e8;
        padding: 8px 12px 8px 12px;
      }
    }
  }

  tbody {
    tr {
      td {
        border: 1px solid #e8e8e8;
        padding: 8px 12px 8px 12px;
      }
    }

    tr {
      th {
        border: 1px solid #e8e8e8;
        padding: 8px 12px 8px 12px;
      }
    }
  }
}
